<template>
  <div class="width_1200 bgc_ffffff">
    <div class="details">
      <h5>{{ details.Title }}</h5>
      <div class="xx">
        <p>作者：{{ details.TeacherName }}</p>
        <p>时间：{{ details.CreateTime | dataYMD }}</p>
      </div>
      <div class="content" v-html="details.Content"></div>
    </div>
    <el-image
      id="preview"
      v-show="false"
      :src="srcList[0]"
      :preview-src-list="srcList"
    >
    </el-image>
  </div>
</template>
<script>
import $ from "jquery";
import { commonQueryTeacherProduceDetail } from "@/api/home";
export default {
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      details: {},
      srcList: []
    };
  },
  mounted () {
    let that = this
    that.getCommonQueryTeacherProduceDetail();
    that.$nextTick(() => {
      $(".content").on("click", "img", function () {
        document.getElementById("preview").click()
      })
    })
  },
  methods: {
    // 作品列表
    async getCommonQueryTeacherProduceDetail () {
      let parm = "?id=" + this.$route.query.Id
      const res = await commonQueryTeacherProduceDetail(parm);
      if (res.success == true) {
        this.details = res.response
        this.srcList = this.common.getImgSrc(res.response.Content)
      } else {
        this.$message.error(res.msg);
      }
    }
  },
};
</script>
<style lang="scss">
.details {
  margin: 30px 0 74px;
  padding: 20px;
  h5 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    // padding: 0 0 16px;
  }
  .xx {
    display: flex;
    justify-content: center;
    color: #999999;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;

    p + p {
      margin-left: 60px;
    }
  }
  .content {
    line-height: 1.8;
    padding: 15px 0;
    font-size: 13px;
    color: #666666;
    /deep/img {
      max-width: 100%;
    }
  }
}
</style>
